import React from "react";
import logo from "../../../images/logo.webp";
import styles from "./AboutUs.module.css";

const AboutUs = () => {
  const lists = [
    {
      name: "BOUTIQUE",
      link: "https://shop.myrelia.fr/",
    },
    {
      name: "CLASSEMENT",
      link: "/classement",
    },
    {
      name: "ACCUEIL",
      link: "/",
    },
  ];
  return (
    <div className={styles.aboutUsWrapper}>
      <div className="container">
        <div className={`${styles.aboutUs} `}>
          <div className={styles.wrapper}>
            <img src={logo} alt="#" className={styles.image} />
          </div>
          <div className={styles.textContainer}>
            <div className={styles.wrapper}>
              <div>
                <h3 className={styles.title}>À PROPOS DE NOUS</h3>
                <p className={styles.text}>
                  Myrelia est un serveur PvP-Faction disponible de la version
                  1.8.X à la version 1.15.X. Il se base sur le thème de l'Egypte
                  antique. Le serveur reprendra les bases du faction mais pas
                  seulement ! Il intègrera une partie RP afin de vous immerger
                  dans un univers inédit!{" "}
                </p>
              </div>
            </div>
            <div className={styles.wrapper}>
              <div>
                <h3 className={styles.title}>LIENS UTILES</h3>
                {lists.map((el, i) => (
                  <div className={styles.list} key={i}>
                    {" "}
                    <span className={styles.circle}></span>{" "}
                    <a
                      href={el.link}
                      target="_blank"
                      rel="noreferrer"
                      className={`${styles.listItem} ${styles.text}`}
                    >
                      {el.name}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.copyRight}>
          <p className={styles.copyRightText}>
            Copyright © 2022 Myrelia - Tous les droits sont réservés.
          </p>
          <p className={styles.copyRightText}> Propulsé par Myrelia </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
