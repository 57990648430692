import React, { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Helmet } from "react-helmet";
import styles from "./JoinUs.module.css";

const JoinUs = () => {
  const [copied, setCopied] = useState(false);

  return (
    <>
      <Helmet>
        <title>Myrelia / Rejoindre</title>
      </Helmet>
      <div className={`${styles.joinUs} container`}>
        <div className={styles.join}>NOUS REJOINDRE</div>
        <div className={styles.boxWrapper}>
          <div className={styles.box}>
            <h4 className={styles.title}>1. Télécharger le jeu</h4>
            <p className={styles.text}>
              Vous devez acheter puis installer le jeu Minecraft Java pour PC:{" "}
              <br />
              <a
                href="https://minecraft.net/store/"
                target="_blank"
                rel="noreferrer"
                className={styles.link}
              >
                https://minecraft.net/store/.
              </a>
            </p>
            <p className={styles.text}>
              Ou si tu est crack télécharge TLauncher : <br />
              <a
                href="https://tlauncher.org/en/"
                target="_blank"
                rel="noreferrer"
                className={styles.link}
              >
                https://tlauncher.org/en/.
              </a>
            </p>
            <p className={styles.text}>
              Après avoir installé le jeu, lancez-le.
            </p>
          </div>
          <div className={styles.box}>
            {" "}
            <h4 className={styles.title}>2. Connectez-vous à MYRELIA</h4>
            <p className={`${styles.text} ${styles.list}`}>
              <span className={styles.circle}></span>
              Sur le jeu, allez dans Multijoueur, puis Nouveau serveur
            </p>
            <p className={`${styles.text} ${styles.list}`}>
              <span className={styles.circle}></span>
              Saisissez play.myrelia.fr dans le champ Adresse et validez.
            </p>
            <CopyToClipboard
              text="PLAY.MYRELIA.FR"
              onCopy={() => setCopied((prev) => !prev)}
            >
              <button className={`${styles.button} ${copied && styles.copy}`}>
                PLAY.MYRELIA.FR
              </button>
            </CopyToClipboard>
          </div>
        </div>
      </div>
    </>
  );
};

export default JoinUs;
