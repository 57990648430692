import React, { useState } from "react";
import { Helmet } from "react-helmet";
import styles from "./Ranking.module.css";
const Ranking = () => {
  const [value, setValue] = useState(0);
  const buttons = ["Faction pvp", " Faction farm"];

  const tableData = [
    [
      { coins: 300, activeEcoins: true, lastName: "Ginolac", voting: 42 },
      { coins: 150, activeEcoins: true, lastName: "Zeldeck", voting: 40 },
      { coins: 50, activeEcoins: true, lastName: "Sikyonn", voting: 40 },
      { coins: 75, ecoins: true, lastName: "Deadshoot7277", voting: 35 },
      { coins: 50, ecoins: true, lastName: "smallfoot1006", voting: 32 },
    ],
    [
      { coins: 300, activeEcoins: true, lastName: "Deadshoot7277", voting: 35 },
      { coins: 150, activeEcoins: true, lastName: "smallfoot1006", voting: 32 },
      { coins: 50, activeEcoins: true, lastName: "Ginolac", voting: 42 },
      { coins: 75, currency: "€ Gemmes", lastName: "PatStA64", voting: 32 },
      {
        coins: 50,
        currency: "€ Gemmes",
        lastName: "Gragonfire242002",
        voting: 32,
      },
    ],
  ];

  return (
    <>
      <Helmet>
        <title>Myrelia / Classement</title>
      </Helmet>
      <section className={`${styles.rankingWrapper} container`}>
        <div className={styles.ranking}>
          <span className={styles.heading}>CLASSEMENT</span>
          <div className={styles.itemContainer}>
            {buttons.map((el, i) => (
              <button
                key={i}
                className={`${value === i && styles.bold} ${styles.item} `}
                onClick={() => setValue(i)}
              >
                {el}
              </button>
            ))}
            {/* <p className={styles.item}>
            Faction pvp <span className={styles.line}>|</span> Faction farm{" "}
          </p>
          <p className={styles.item}>
            <span className={`${styles.line} ${styles.line2}`}>|</span> Vote{" "}
            <span className={styles.line}>|</span> Monnaie
          </p> */}
          </div>
        </div>
        <div className={styles.table}>
          <div className={styles.row}>
            <p className={styles.text}>
              <span className={styles.id}>#</span> RÉCOMPENSES
            </p>
            <p className={styles.text}>Faction</p>
            <p className={styles.text}>Points</p>
          </div>
          {tableData[value].map((el, i) => (
            <div className={styles.row}>
              <div className={styles.textContainer}>
                <span className={`${styles.id} ${styles.text}`}>{i + 1} </span>
                <p
                  className={`${styles.text} ${
                    el.activeEcoins && styles.activeEcoin
                  }`}
                >
                  <span>
                    {el.coins}
                    {el.currency && el.currency}
                  </span>{" "}
                  &nbsp;
                  <span className={styles.ecoin}>
                    {el.activeEcoins && "€ Paypal"}
                  </span>
                  <span className={styles.ecoin}>
                    {el.ecoins && "€ Paypal"}
                  </span>
                </p>
              </div>
              <p className={styles.text}>{el.lastName}</p>
              <p className={styles.text}>{el.voting}</p>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default Ranking;
