import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import JoinUs from "./components/JoinUs/JoinUs";
import Ranking from "./components/Ranking/Ranking";
import Home from "./components/Home/Home";
import Faq from "./components/Faq/Faq";

function App() {
  return (
    <BrowserRouter>
      <Layout>
        {" "}
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/rejoindre" element={<JoinUs />}></Route>
          <Route path="/classement" element={<Ranking />}></Route>
          <Route path="/faq" element={<Faq />}></Route>
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
