import React from "react";
import logo from "../../images/logo.webp";
import SingleFaq from "./SingleFaq/SingleFaq";
import styles from "./Faq.module.css";
import { Helmet } from "react-helmet";

const Faq = () => {
  const faqData = [
    {
      question: "Le serveur est-il Farm2Win ? ",
      answer:
        "Le serveur est en effet Farm2Win, avec un équilibrage parfait entre l'univers du farming et le pvp.",
    },
    {
      question:
        "Doit-on obligatoirement payer pour avoir les meilleurs items & grades ?",
      answer:
        "L'avantage de Myrelia est que vous pouvez tout avoir sans dépenser un seul euro !",
    },
    {
      question: "Le serveur propose t-il des grades pour les youtubeurs ?",
      answer:
        "Myrelia propose et met en avant les youtubeurs dans des salons dédié pour avoir de la visibilité.",
    },
    {
      question: "Le serveur Myrelia recrute t-il des staffs actuellement ?",
      answer:
        "Nous recrutons, vous pouvez postuler en cliquant sur ce lien, nos responsables vous répondra dans les plus brefs délais.",
    },
  ];
  return (
    <>
      <Helmet>
        <title>Myrelia / FAQ</title>
      </Helmet>
      <div className="container">
        <div className={styles.header}>FAQ</div>
        <div className={styles.wrapper}>
          <img src={logo} alt="#" className={styles.image} />{" "}
          <div className={styles.faqContainer}>
            {faqData.map((el, i) => (
              <SingleFaq {...el} index={i + 1} key={i} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;
